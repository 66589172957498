import React, { useContext } from "react";
import { SttBox, SttNotification, SttTranslateHook } from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core";
import usuario from "../../signals/usuario";
import socket, { erroConexacaoSocket } from "../../signals/socket";
import { temPermissaoRBAC } from '../../security/rbac';
import { EVENTOS_SOCKET, PERMISSOES } from '../../common/Constants';
import { useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import { connect } from 'socket.io-client';
import PainelDireita from "./painel-direita";
import { usuarioEmAtendimento } from "../../signals/atendimentos";
import { getAuthrizationHeader } from "../../request";

const useStyles = makeStyles(theme => ({
    wrapper: {
        height: 'calc(100vh - 104px)',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 124px)',
        },
        flexDirection: 'column',
        display: 'flex',
        overflow: 'hidden'
    },
    wrapperAtendimento: {
        height: 'calc(100vh - 104px)',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 124px)',
        },
        flexDirection: 'row',
        width: '100%',
        display: 'flex',
        overflow: 'hidden'
    },
    notificationBox: {
        width: '100%',
        height: '100px',
        minHeight: '100px',
        marginBottom: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
            height: '220px',
            minHeight: '220px',
        },
    },
    notificacao: {
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: 'larger'
    },
}));

const HomePaciente = ({ cnes }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    useSignalEffect(() => {
        const options = {
            query: { cnes },
            forceNew: false,
            extraHeaders: {
                ...getAuthrizationHeader()
            },
            // transports: ['websocket', 'polling'],
            reconnectionDelay: 5000, // defaults to 1000
            reconnectionDelayMax: 8000 // defaults to 5000
        };
        if (global.gConfig.url_base_socket_path !== '') {
            options.path = global.gConfig.url_base_socket_path;
        }
        socket.value = connect(global.gConfig.url_base_socket, options);

        const dadosUsuario = {
            nome: usuario.value.nome,
            atendente: temPermissaoRBAC(usuario, PERMISSOES.INTERNO),
            idPaciente: usuario.value.idPaciente,
            id: usuario.value.idPaciente,
            cpf: usuario.value.cpf,
            // uf: usuario.value.estado,
            // municipio: usuario.value.cidade,
        };

        usuarioEmAtendimento.value = dadosUsuario;

        socket.value.on("connect", () => {
            if (erroConexacaoSocket.value) {
                window.location.reload();
            }

            socket.value.emit(EVENTOS_SOCKET.DADOS_USUARIO, JSON.stringify(dadosUsuario));
        });

        function handleErrors(err) {
            erroConexacaoSocket.value = true;

            const box = document.getElementById('box-atendimento');

            const buttons = box.getElementsByTagName('button');
            for (let i = 0; i < buttons.length; i++) {
                buttons[i].disabled = 'disabled';
            }

            const inputs = box.getElementsByTagName('input');
            for (let i = 0; i < inputs.length; i++) {
                inputs[i].disabled = 'disabled';
            }
        }

        socket.value.on('connect_error', err => handleErrors(err))
        socket.value.on('connect_failed', err => handleErrors(err))
        socket.value.on('disconnect', err => handleErrors(err))

        return () => {
            socket.value.disconnect();
            socket.value = null;
        }

    });

    return (
        <SttBox className={classes.wrapper}>
            {
                erroConexacaoSocket.value &&
                <SttBox className={classes.notificationBox}>
                    <SttNotification severity='error' className={classes.notificacao}>
                        <div dangerouslySetInnerHTML={{ __html: strings.avisoSemConexao }} />
                    </SttNotification>
                </SttBox>
            }
            <SttBox className={classes.wrapperAtendimento} id="box-atendimento">
                {/* conteúdo atendido */}
                <PainelDireita />
            </SttBox>
        </SttBox>
    );
}

export default HomePaciente;