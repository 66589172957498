import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttCircularProgress,
    SttCacheManager
} from '@stt-componentes/core';
import Menu from '../componentes/menu';
import { Routes, Route } from 'react-router-dom';
import { Cache } from '@stt-componentes/cache';
import Erro401 from './erro/401';
import usuario from '../signals/usuario';
import HomeAtendente from './home/atendente';

const useStyles = makeStyles(theme => ({
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));

const Home = () => {
    const classes = useStyles();

    return (
        usuario.value.carregado ?
            <div className={classes.wrapper}>
                <Menu />
                <Routes>
                    <Route path="/" element={<HomeAtendente />} />
                    <Route path="/nao-autorizado" exact element={<Erro401 />} />
                </Routes>
            </div>
            :
            <>
                <SttCacheManager config={global.gConfig} onSync={loaded => {
                    const user = Cache.getUserInfo();
                    user.carregado = loaded;
                    usuario.value = user;
                }} />
                <div className={classes.loading}>
                    <SttCircularProgress />
                </div>
            </>
    );

};

export default Home;