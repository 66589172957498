import { VINCULO_PERFIL_ESTADUAL, VINCULO_PERFIL_INSTITUICAO } from "../common/Constants";

const temPerfilRBAC = (usuario, perfil) => {
    if (!usuario.value) {
        return false;
    }
    if (!usuario.value.perfisRBAC || !perfil) {
        return false;
    }
    if (Array.isArray(perfil)) {
        return perfil.some(p => usuario.value.perfisRBAC.some(prbac => prbac.identificador === p));
    }
    return usuario.value.perfisRBAC.some(p => p.identificador === perfil);
};

const temPermissaoRBAC = (usuario, permissao) => {
    if (!usuario.value) {
        return false;
    }
    if (!usuario.value.permissoesRBAC || !permissao) {
        return false;
    }
    if (Array.isArray(permissao)) {
        return permissao.some(p => usuario.value.permissoesRBAC.includes(p));
    }
    return usuario.value.permissoesRBAC.includes(permissao);
};

const temVinculoEstadual = (usuario) => {
    if (!usuario.value) {
        return false;
    }
    if (!usuario.value.perfisRBAC) {
        return false;
    }
    return usuario.value.perfisRBAC.some(p => p.vinculo === VINCULO_PERFIL_ESTADUAL);
};

const pegarEstadosVinculoPerfil = (usuario, perfil) => {
    if (!usuario.value) {
        return false;
    }
    if (!usuario.value.perfisRBAC) {
        return false;
    }
    let estadosPerfil = [];
    usuario.value.perfisRBAC.forEach(p => {
        if (p.vinculo === VINCULO_PERFIL_ESTADUAL && p.estados && p.identificador === perfil) {
            let estados = p.estados.map(e => e.id);
            estadosPerfil = estadosPerfil.concat(estados);
        }
    });
    return estadosPerfil;
}

const pegarInstituicoesVinculoPerfil = (usuario) => {
    let instituicoesPerfil = [];
    usuario.value.perfisRBAC.forEach(p => {
        if (p.vinculo === VINCULO_PERFIL_INSTITUICAO && p.instituicoes) {
            let instituicoes = p.instituicoes.map(inst => inst.id);
            instituicoesPerfil = instituicoesPerfil.concat(instituicoes);
        }
    });
    return instituicoesPerfil;
}

const temVinculoInstituicao = (usuario) => {
    return usuario.value.perfisRBAC.some(p => p.vinculo === VINCULO_PERFIL_INSTITUICAO);
};

export {
    temPerfilRBAC,
    temPermissaoRBAC,
    temVinculoEstadual,
    pegarEstadosVinculoPerfil,
    pegarInstituicoesVinculoPerfil,
    temVinculoInstituicao
};