import React, { Suspense, lazy, memo, useContext, useEffect } from "react";
import {
    SttBox,
    SttCircularProgress,
    SttHeading,
    SttTabs,
    SttTranslateHook
} from '@stt-componentes/core';
import { useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import { IconButton, Tooltip, makeStyles } from "@material-ui/core";
import { atendimentoEncerrado, idAtendimento, usuarioEmAtendimento } from "../../signals/atendimentos";
import { batch, signal } from "@preact/signals-react";
import axios from 'axios';
import { getHeaders } from '../../request'
import moment from 'moment';
import { temPermissaoRBAC } from "../../security/rbac";
import usuario from "../../signals/usuario";
import { PERMISSOES } from "../../common/Constants";
import { SetaDuplaIcon } from "@stt-componentes/icons";

const useStyles = makeStyles(theme => ({
    heading: {
        marginLeft: theme.spacing(2)
    },
    headerBox: {
        height: '63px',
        minHeight: '63px',
        padding: "0px 15px",
        borderBottom: '1px solid #D9D9D9',
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'row',
        paddingLeft: '15px',
        alignItems: "center",
        minWidth: '250px',
        width: '100%'
    },
    tabsBox: {
        width: '100%',
        minWidth: '250px'
    },
    iconButton: {
        marginLeft: '5px',
        marginRight: '5px',
    },
    wrapperTabs: {
        padding: 0,
        height: '100%'
    },
    tab: {
        height: '600px',
        overflow: 'scroll'
    }
}));

const ChatHistoricoSuspense = memo((props) => {
    const HistoricoChatContainer = lazy(() => import('./chat-container'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <HistoricoChatContainer {...props} />
        </Suspense>
    )
});

const AnexosHistoricoSuspense = memo((props) => {
    const HistoricoAnexoContainer = lazy(() => import('./anexo-container'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <HistoricoAnexoContainer {...props} />
        </Suspense>
    )
});

const RegistrosHistoricoSuspense = memo((props) => {
    const HistoricoRegistrosContainer = lazy(() => import('./registro-container'));

    return (
        <Suspense fallback={<SttCircularProgress color="primary" />}>
            <HistoricoRegistrosContainer {...props} />
        </Suspense>
    )
});

const historico = signal([]);
const carregouHistorico = signal(false);
const abas = signal([]);
const abaAtiva = signal('0');

const resetHistorico = () => {
    batch(() => {
        historico.value = [];
        carregouHistorico.value = false;
        abas.value = [];
        abaAtiva.value = '0';
    });
}

const HistoricoContainer = ({ historicoAberto }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    useEffect(() => {
        if (!carregouHistorico.value) {
            console.log('vai baixar histórico')
            baixarHistorico();
        }
    }, []);

    useSignalEffect(() => {
        if (!usuarioEmAtendimento.value) {
            resetHistorico();
        }
    })

    const baixarHistorico = () => {
        axios.get(`${global.gConfig.url_base_backend}/atendimento/historico`, { params: { pacienteAtendido: usuarioEmAtendimento.value.idPaciente }, headers: getHeaders() })
            .then((response) => {
                const { data } = response;

                let itensHistorico = data.data.itens;
                if (itensHistorico.length === 0) {
                    // TRATAR PARA NÃO EXIBIR OS TABS
                    return;
                }

                if (idAtendimento.value && !atendimentoEncerrado.value) {
                    itensHistorico = itensHistorico.filter(ih => ih._id !== idAtendimento.value)
                }

                itensHistorico.forEach(i => {
                    const dataInicioAtendimento = moment(i.dataHoraInicio).format('HH:mm - DD/MM/YYYY');

                    if (i.mensagens.length === 0) {
                        i.mensagens.push({
                            notificacao: true,
                            mensagem: strings.nenhumaMensagemEnviadaAtendimento,
                        });
                    }
                    i.mensagens.unshift({
                        notificacao: true,
                        mensagem: `Atendimento iniciado às ${dataInicioAtendimento}`,
                        atendimento: true
                    });
                    if (i.dataHoraFim) {
                        const dataFimAtendimento = moment(i.dataHoraFim).format('HH:mm - DD/MM/YYYY');
                        i.mensagens.push({
                            notificacao: true,
                            mensagem: `Atendimento finalizado às ${dataFimAtendimento}`,
                            atendimento: true
                        });
                    }
                });

                carregouHistorico.value = true;
                historico.value = itensHistorico;
                const abasHistorico = [
                    {
                        titulo: strings.chat,
                        conteudo: ChatHistoricoSuspense,
                        key: 'ChatHistoricoSuspense',
                        permanente: true,
                        historico: historico.value
                    },
                    {
                        titulo: strings.anexos,
                        conteudo: AnexosHistoricoSuspense,
                        key: 'AnexosHistoricoSuspense',
                        permanente: true,
                        historico: historico.value
                    },
                ];
                if (temPermissaoRBAC(usuario, PERMISSOES.ATENDIMENTO) && usuario.value.cbo.some(cbo => global.gConfig.codigo_cbo_registro.includes(parseInt(cbo.codigo_cbo_familia)))) {
                    abasHistorico.push({
                        titulo: strings.registros,
                        conteudo: RegistrosHistoricoSuspense,
                        key: 'RegistrosHistoricoSuspense',
                        permanente: true,
                        historico: historico.value
                    })
                }
                abas.value = abasHistorico
            })
            .catch(err => console.log(err));
    }

    return (
        <>
            {/* Container com o header do painel */}
            <SttBox className={classes.headerBox}>
                <Tooltip title={strings.fecharHistorico}>
                    <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                        onClick={() => {
                            historicoAberto.value = false;
                        }}>
                        <SetaDuplaIcon />
                    </IconButton>
                </Tooltip>
                <SttHeading variant="h2" color="primary">{strings.historico}</SttHeading>
            </SttBox>
            <SttBox className={classes.tabsBox}>
                <SttTabs abas={abas.value}
                    className={classes.wrapperTabs}
                    handleChangeAbaAtiva={(abaNova) => abaAtiva.value = abaNova}
                    abaAtiva={abaAtiva.value}
                    permanente={true}
                    preload={false}
                />
            </SttBox>
        </>
    );
}

export default HistoricoContainer;