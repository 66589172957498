import React, { useContext } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import SttHeading from '@stt-componentes/core/dist/lib/SttTypography/SttHeading';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {
    SttText,
    SttTranslateHook,
} from '@stt-componentes/core';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(3),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    }
}));

const ErroCnes = ({ verificacao, cnes }) => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);


    return (
        <div className={classes.root}>
            <SttHeading variant="h1">
                <ErrorOutlineIcon />
                Não autorizado
            </SttHeading>
            {
                verificacao.cnesInvalido && <SttText>{strings.cnesInvalido(cnes)}</SttText>
            }
            {
                !verificacao.cnesInvalido && !verificacao.consultorioHabilitado && <SttText>{strings.cnesConsultorioDesabilitado(cnes)}</SttText>
            }
        </div>
    );
};

export default ErroCnes;
