import React, { useEffect } from "react";
import axios from 'axios';
import { useSignal, useSignals } from "@preact/signals-react/runtime";

const useVerificacaoConsultorioVirtual = (cnes) => {
    useSignals();

    const cnesInvalido = useSignal(false);
    const cnesCarregado = useSignal(false);
    const consultorioHabilitado = useSignal(false);

    useEffect(() => {
        axios.get(`${global.gConfig.url_base_backend}/verificar-consultorio-habilitado/${cnes}`)
            .then((response) => {
                console.log(response.data)
                if (response.data.data.cnesIncorreto) {
                    cnesInvalido.value = true;
                    return;
                }
                consultorioHabilitado.value = response.data.data.consultorioHabilitado;
            })
            .catch(() => {
                cnesInvalido.value = true;
            })
            .finally(() => {
                cnesCarregado.value = true;
            });
    }, []);

    if (!cnesCarregado.value) {
        return {
            cnesCarregado: false
        }
    }

    return {
        cnesInvalido: cnesInvalido.value,
        cnesCarregado: cnesCarregado.value,
        consultorioHabilitado: consultorioHabilitado.value,
    }
}

export default useVerificacaoConsultorioVirtual;