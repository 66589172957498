import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import {
    MateriaisSuporte,
    SttHeader,
    SttSplashScreen,
    SttNotificationScreen,
    SttTranslateHook,
    SttMainNavigationBar
} from '@stt-componentes/core';
import { PERMISSOES } from '../common/Constants';
import { getHeaders } from '../request';
import usuario from '../signals/usuario';
import { signal, useSignalEffect } from '@preact/signals-react';
import { useSignal, useSignals } from '@preact/signals-react/runtime';
import { temPermissaoRBAC } from '../security/rbac';

const openMS = signal(false);
const openSS = signal(false);

const Menu = () => {
    const navigate = useNavigate();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const exibirNotificacao = useSignal(false);

    useSignalEffect(() => {
        if (usuario.value) {
            if (!temPermissaoRBAC(usuario, PERMISSOES.TELEATENDIMENTO)) {
                navigate(`/nao-autorizado`);
                return;
            }
            exibirNotificacao.value = !usuario.value.idPaciente;
        }
    });

    return (
        <div>
            <SttHeader
                config={global.gConfig}
                titulo={global.gConfig.instancia_nome}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => openSS.value = !openSS.value,
                    onClickMateriaisSuporte: () => openMS.value = !openMS.value,
                    strings
                }}
            />
            <SttMainNavigationBar
                config={global.gConfig}
                titulo={global.gConfig.modulo_nome}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                textoAjuda={global.gConfig.texto_ajuda}
                perfisRBAC={usuario.value.perfisRBAC}
                open={openSS.value}
                setOpen={(open) => { openSS.value = open }}
            />

            <MateriaisSuporte
                strings={strings}
                config={global.gConfig}
                headers={getHeaders()}
                open={openMS.value}
                setOpen={(open) => { openMS.value = open }}
            />
            {
                exibirNotificacao.value &&
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={getHeaders()}
                    perfisRBAC={usuario.value.perfisRBAC}
                />
            }
        </div>
    );
};

export default Menu;