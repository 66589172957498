import React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
    SttCircularProgress,
    SttCacheManager
} from '@stt-componentes/core';
import Menu from '../componentes/menu';
import { Routes, Route, useParams } from 'react-router-dom';
import { Cache } from '@stt-componentes/cache';
import Erro401 from './erro/401';
import usuario from '../signals/usuario';
import HomePaciente from './home/paciente';
import useVerificacaoConsultorioVirtual from '../hooks/verificacao-consultorio-virtual';
import ErroCnes from './erro/erro-cnes';

const useStyles = makeStyles(theme => ({
    wrapper: {
    },
    loading: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: '300px'
    }
}));

const Home = () => {
    const classes = useStyles();

    const params = useParams();
    const cnes = params.cnes.split('&')[0];

    const verificacao = useVerificacaoConsultorioVirtual(cnes)

    if (!verificacao.cnesCarregado) {
        return (
            <div className={classes.loading}>
                <SttCircularProgress />
            </div>
        )
    }

    if (!verificacao.consultorioHabilitado || verificacao.cnesInvalido) {
        return (
            <ErroCnes
                cnes={cnes}
                verificacao={verificacao}
            />
        )
    }

    return (
        usuario.value.carregado ?
            <div className={classes.wrapper}>
                <Menu />
                <Routes>
                    <Route path="/" exact element={<HomePaciente cnes={cnes} />} />
                    <Route path="/nao-autorizado" exact element={<Erro401 />} />
                </Routes>
            </div>
            :
            <>
                <SttCacheManager config={global.gConfig}
                    onSync={loaded => {
                        const user = Cache.getUserInfo();
                        user.carregado = loaded;
                        usuario.value = user;
                    }}
                    hasExternalToken={true}
                    redirectUrl={`${global.gConfig.url_redirect_cadastro_paciente}?teleatendimento_cnes=${cnes}`}
                />
                <div className={classes.loading}>
                    <SttCircularProgress />
                </div>
            </>
    );

};

export default Home;