import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Interno from './interno';
import InternoPaciente from './interno-paciente';
import alerta from '../signals/alerta';
import carregando from '../signals/carregando';
import { useSignals } from '@preact/signals-react/runtime';
import { SttAlerta, SttLoading } from '@stt-componentes/core';
import Erro401 from './erro/401';

const Home = () => {
    useSignals();

    return (
        <BrowserRouter basename={`/${global.gConfig.basename}`}>
            <Routes>
                <Route path="/atendimento/:cnes" exact element={<InternoPaciente />} />
                <Route path="/" element={<Interno />} />
                <Route path="/nao-autorizado" exact element={<Erro401 />} />

            </Routes>
            <SttAlerta {...alerta.value} />
            <SttLoading {...carregando.value} />
        </BrowserRouter>
    );
};

export default Home;
