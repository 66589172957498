import React, { useContext } from "react";
import {
    SttBox,
    SttTranslateHook,
    SttInput,
    SttEmojiPicker,
    SttText
} from '@stt-componentes/core';
import { useSignal, useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import socket from "../../signals/socket";
import usuario from "../../signals/usuario";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { atendimentoEncerrado, emAtendimento, idSalaAtendimento, quantidadeMembrosSala } from "../../signals/atendimentos";
import { temPermissaoRBAC } from "../../security/rbac";
import EnvioAnexoContainer from "../anexos/modal-envio";
import { makeStyles } from "@material-ui/core";
import { EVENTOS_SOCKET, PERMISSOES } from "../../common/Constants";
import {
    AnexoIcon,
    EnviarMensagemIcon
} from '@stt-componentes/icons';

const useStyles = makeStyles(theme => ({
    iconButton: {
        marginLeft: theme.spacing(0.5),
    },
    digitando: {
        marginTop: theme.spacing(1),
        marginLeft: theme.spacing(0.5)
    },
    boxDigitando: {
        display: 'flex',
        width: '100%',
        marginBottom: '-4px'
    },
    boxContainer: {
        minHeight: '63px',
        alignItems: 'center',
        zIndex: 1000,
        padding: '0px 15px',
        borderTop: "1px solid #D9D9D9",
        background: 'white'
    }
}));

const ChatFormContainer = () => {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignals();

    const textoMensagem = useSignal('');
    const inserirAnexo = useSignal(false);
    const usuariosDigitando = useSignal([]);

    useSignalEffect(() => {
        if (socket.value) {
            socket.value.on(EVENTOS_SOCKET.AVISO_DIGITANDO, (dados) => {
                dados = JSON.parse(dados);
                usuariosDigitando.value = dados.filter(d => d.id != usuario.value.idFuncionario && d.id != usuario.value.idPaciente);
            })
        }
    });

    useSignalEffect(() => {
        const dadosDigitando = {
            digitando: textoMensagem.value.trim().length > 0,
            idSala: idSalaAtendimento.value,
            nome: usuario.value.nome,
            id: usuario.value.idFuncionario || usuario.value.idPaciente,
        }
        socket.value.emit(EVENTOS_SOCKET.ENVIAR_AVISO_DIGITANDO, JSON.stringify(dadosDigitando));
    });

    const handleChangeMensagem = event => {
        textoMensagem.value = event.target.value;
    };

    const tratarTeclaEnter = event => {
        if (event.key == 'Enter' && !event.shiftKey) {
            event.preventDefault();
            enviarMensagem();
        }
    };

    const enviarMensagem = () => {
        if (textoMensagem.value === '') {
            return;
        }

        const dadosMsg = {
            mensagem: textoMensagem.value.trim(),
            idSala: idSalaAtendimento.value,
            responsavel: {
                nome: usuario.value.nome,
                id: usuario.value.idFuncionario || usuario.value.idPaciente,
                cpf: usuario.value.cpf,
            }
        }
        socket.value.emit(EVENTOS_SOCKET.ENVIAR_MENSAGEM, JSON.stringify(dadosMsg));
        textoMensagem.value = '';
    }

    return (
        <>
            <SttBox className={classes.boxContainer}>
                {
                    usuariosDigitando.value.length > 0 &&
                    <SttBox className={classes.boxDigitando}>
                        <SttText variant="caption" size="small" color="textPrimary" className={classes.digitando}>
                            {usuariosDigitando.value.map(ud => ud.nome.split(' ')[0]).join(', ')} está digitando...
                        </SttText>
                    </SttBox>
                }

                <SttBox
                    minHeight="62px"
                    alignItems="center"
                    display="flex"
                    width="100%"
                >
                    <SttInput label={strings.digiteMensagem}
                        fullWidth={true}
                        disabled={atendimentoEncerrado.value || !emAtendimento.value || (!temPermissaoRBAC(usuario, PERMISSOES.ATENDIMENTO) && quantidadeMembrosSala.value === 1)}
                        inputProps={{ 'aria-label': 'mensagem' }}
                        multiline
                        minRows={1}
                        maxRows={4}
                        onChange={handleChangeMensagem}
                        onKeyPress={tratarTeclaEnter}
                        value={textoMensagem.value}
                    />
                    {
                        (atendimentoEncerrado.value || !emAtendimento.value || (!temPermissaoRBAC(usuario, PERMISSOES.ATENDIMENTO) && quantidadeMembrosSala.value === 1))
                            ?
                            <>
                                <IconButton edge="end" disabled={true}>
                                    <AnexoIcon />
                                </IconButton>
                                <SttEmojiPicker disabled={true} />
                                <IconButton edge="end" disabled={true} className={classes.iconButton} >
                                    <EnviarMensagemIcon />
                                </IconButton>
                            </>
                            :
                            <>
                                <Tooltip title={strings.anexos}>
                                    <IconButton disableRipple={true} edge="end"
                                        onClick={() => {
                                            inserirAnexo.value = true;
                                        }}>
                                        <AnexoIcon />
                                    </IconButton>
                                </Tooltip>
                                <SttEmojiPicker selecionarEmoji={strings.selecionarEmoji}
                                    setMensagemEmoji={(emojiObject) => {
                                        textoMensagem.value = `${textoMensagem.value} ${emojiObject.emoji}`;
                                    }} />
                                <Tooltip title={strings.enviarMensagem}>
                                    <IconButton disableRipple={true} edge="end" className={classes.iconButton}
                                        onClick={enviarMensagem}>
                                        <EnviarMensagemIcon />
                                    </IconButton>
                                </Tooltip>
                            </>
                    }
                </SttBox>
            </SttBox>
            {
                inserirAnexo.value &&
                <EnvioAnexoContainer inserirAnexo={inserirAnexo} />
            }
        </>
    );
};

export default ChatFormContainer;