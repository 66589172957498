import React, { useContext, useEffect } from "react";
import { SttBox, SttHidden, SttNotification, SttTranslateHook } from '@stt-componentes/core';
import { makeStyles } from "@material-ui/core";
import usuario from "../../signals/usuario";
import socket, { erroConexacaoSocket } from "../../signals/socket";
import { pegarInstituicoesVinculoPerfil, temVinculoInstituicao } from '../../security/rbac';
import { EVENTOS_SOCKET } from '../../common/Constants';
import { useSignal, useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import { connect } from 'socket.io-client';
import PainelEsquerda from "./painel-esquerda";
import PainelDireita from "./painel-direita";
import { instituicaoAtendimento, listaAtendimentoAberto } from "../../signals/atendimentos";
import { getAuthrizationHeader } from "../../request";
import SelecaoInstituicao from "../../componentes/instituicao";

const useStyles = makeStyles(theme => ({
    wrapper: {
        height: 'calc(100vh - 104px)',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 124px)',
        },
        flexDirection: 'column',
        display: 'flex',
        overflow: 'hidden'
    },
    wrapperAtendimento: {
        height: 'calc(100vh - 104px)',
        [theme.breakpoints.down('sm')]: {
            height: 'calc(100vh - 124px)',
        },
        flexDirection: 'row',
        display: 'flex',
        overflow: 'hidden'
    },
    fullLeftBox: {
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },
    hiddenRigthBox: {
        height: '100%',
        width: '0%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },

    leftBox: {
        height: '100%',
        width: '20%',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
            width: '30%',
        },
    },
    rigthBox: {
        height: '100%',
        width: '80%',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            width: '70%',
        },
    },

    shortLeftBox: {
        height: '100%',
        width: '75px',
        minWidth: '75px',
        maxWidth: '75px',
        overflow: 'hidden',
    },
    bigRigthBox: {
        height: '100%',
        width: 'calc(100% - 75px)',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    notificationBox: {
        width: '100%',
        height: '100px',
        minHeight: '100px',
        marginBottom: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
            height: '220px',
            minHeight: '220px',
        },

    },
    notificacao: {
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: 'larger'
    },
}));

const criarArrayInstituicoesSelecao = (instituicoesPerfil, habilitacao) => {
    const instSelecao = [];
    instituicoesPerfil.forEach(ip => {
        habilitacao.forEach(ih => {
            if (ip === ih.id) {
                instSelecao.push({
                    cnes: ih.cnes,
                    nome: ih.nome
                });
            }
        });
    });
    return instSelecao;
}

const HomeAtendente = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    useSignals();

    const exibirSelecaoInstituicao = useSignal(false);
    const instituicoesSelecao = useSignal([])

    useEffect(() => {
        if (temVinculoInstituicao(usuario)) {
            const insts = criarArrayInstituicoesSelecao(pegarInstituicoesVinculoPerfil(usuario), usuario.value.habilitacao.vinculo);
            usuario.value.ehAtendente = true;
            if (insts.length === 1) {
                instituicaoAtendimento.value = insts[0];
                return;
            }
            instituicoesSelecao.value = insts;
            exibirSelecaoInstituicao.value = true;
        }
    }, [])

    useSignalEffect(() => {
        if (instituicaoAtendimento.value) {
            const options = {
                query: {
                    cnes: instituicaoAtendimento.value.cnes,
                },
                forceNew: false,
                extraHeaders: {
                    ...getAuthrizationHeader()
                },
                // transports: ['websocket', 'polling'],
                reconnectionDelay: 5000, // defaults to 1000
                reconnectionDelayMax: 8000 // defaults to 5000
            };
            if (global.gConfig.url_base_socket_path !== '') {
                options.path = global.gConfig.url_base_socket_path;
            }
            socket.value = connect(global.gConfig.url_base_socket, options);

            const dadosUsuario = {
                cbo: usuario.value.cbo?.length ? usuario.value.cbo[0] : null,
                nome: usuario.value.nome,
                atendente: true,
                idFuncionario: usuario.value.idFuncionario,
                id: usuario.value.idFuncionario,
                cpf: usuario.value.cpf,
                uf: usuario.value.estado,
                municipio: usuario.value.cidade,
            };

            socket.value.on("connect", () => {
                if (erroConexacaoSocket.value) {
                    window.location.reload();
                }

                socket.value.emit(EVENTOS_SOCKET.DADOS_USUARIO, JSON.stringify(dadosUsuario));
            });

            function handleErrors(err) {
                erroConexacaoSocket.value = true;

                const box = document.getElementById('box-atendimento');

                const buttons = box.getElementsByTagName('button');
                for (let i = 0; i < buttons.length; i++) {
                    buttons[i].disabled = 'disabled';
                }

                const inputs = box.getElementsByTagName('input');
                for (let i = 0; i < inputs.length; i++) {
                    inputs[i].disabled = 'disabled';
                }
            }

            socket.value.on('connect_error', err => handleErrors(err))
            socket.value.on('connect_failed', err => handleErrors(err))
            socket.value.on('disconnect', err => handleErrors(err))

            return () => {
                socket.value.disconnect();
                socket.value = null;
            }
        }

    });

    return (
        <SttBox className={classes.wrapper}>
            {
                erroConexacaoSocket.value &&
                <SttBox className={classes.notificationBox}>
                    <SttNotification severity='error' className={classes.notificacao}>
                        <div dangerouslySetInnerHTML={{ __html: strings.avisoSemConexao }} />
                    </SttNotification>
                </SttBox>
            }
            <SttBox className={classes.wrapperAtendimento} id="box-atendimento">
                <SttHidden xsDown>
                    <SttBox className={listaAtendimentoAberto.value ? classes.leftBox : classes.shortLeftBox}>
                        <PainelEsquerda />
                    </SttBox>
                </SttHidden>
                <SttHidden smUp>
                    <SttBox className={listaAtendimentoAberto.value ? classes.fullLeftBox : classes.shortLeftBox}>
                        <PainelEsquerda />
                    </SttBox>
                </SttHidden>

                <SttBox sx={{
                    border: ".05px solid #e9e9e9",
                }} />

                <SttHidden xsDown>
                    <SttBox className={listaAtendimentoAberto.value ? classes.rigthBox : classes.bigRigthBox}>
                        <PainelDireita />
                    </SttBox>
                </SttHidden>
                <SttHidden smUp>
                    <SttBox className={listaAtendimentoAberto.value ? classes.hiddenRigthBox : classes.bigRigthBox}>
                        <PainelDireita />
                    </SttBox>
                </SttHidden>
            </SttBox>
            <SelecaoInstituicao
                instituicoes={instituicoesSelecao.value}
                visivel={exibirSelecaoInstituicao.value}
                confirmarSelecao={(inst) => {
                    exibirSelecaoInstituicao.value = false;
                    instituicaoAtendimento.value = inst;
                }}
            />
        </SttBox>
    );
}

export default HomeAtendente;