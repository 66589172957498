import React, { useContext } from "react";
import { SttBox, SttHidden, SttTranslateHook } from '@stt-componentes/core';
import { useSignal, useSignalEffect, useSignals } from "@preact/signals-react/runtime";
import { Drawer, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import AtendimentoContainer from "../../componentes/atendimento-container";
import HistoricoContainer from "../../componentes/historico";
import { emChamadaVideo, historicoAberto } from "../../signals/atendimentos";
import { EVENTOS_SOCKET } from "../../common/Constants";
import VideoScreenBBB from "../../componentes/video/bbb";
import socket, { erroConexacaoSocket } from "../../signals/socket";
import ChatIcon from '@material-ui/icons/Chat';

const useStyles = makeStyles(theme => ({
    wrapper: {
        width: '100%',
        height: '100%',
        flexDirection: 'row',
        display: 'flex',
        overflow: 'hidden'
    },
    chatBox: {
        height: '100%',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
    videoBox: {
        height: '100%',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    historicoBox: {
        overflowY: 'auto',
        overflowX: 'hidden',
        width: (props) => props.widthChat,
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawer: {
        maxWidth: '100%',
        width: '100%',
        flexShrink: 0,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaper: {
        top: 'auto',
        maxWidth: '100%',
        width: '100%'
    },
    openChatButton: {
        padding: theme.spacing(1.5),
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            top: 110,
        },
        zIndex: 10,
        borderRadius: '0 0 15px 0',
        backgroundColor: 'white'
    },
}));

const PainelDireita = () => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    let widthChat = '100%';
    if (historicoAberto.value && emChamadaVideo.value) {
        widthChat = '33%';
    } else if (historicoAberto.value || emChamadaVideo.value) {
        widthChat = '50%';
    }
    const classes = useStyles({ widthChat, erroSocket: erroConexacaoSocket.value });
    useSignals();

    const atendimentoAberto = useSignal(true);

    useSignalEffect(() => {
        if (socket.value) {
            socket.value.on(EVENTOS_SOCKET.STATUS_ATENDIMENTO_VIDEO, (dados) => {
                dados = JSON.parse(dados);
                emChamadaVideo.value = dados.videoAtivo;
            });
        }
    });

    return (
        <SttBox className={classes.wrapper}>
            {/* Container de mensagens */}
            <SttHidden xsDown>
                <SttBox className={classes.chatBox}
                    sx={{
                        width: widthChat
                    }}>
                    <AtendimentoContainer historicoAberto={historicoAberto} />
                </SttBox>
                <SttBox sx={{
                    border: ".05px solid #e9e9e9",
                }} />
            </SttHidden>

            <SttHidden smUp>
                {
                    !atendimentoAberto.value &&
                    <Tooltip title={strings.chat}>
                        <IconButton disableRipple={true} className={classes.openChatButton} onClick={() => atendimentoAberto.value = true}>
                            <ChatIcon />
                        </IconButton>
                    </Tooltip>
                }
                <SttBox className={classes.chatBox}
                    sx={{
                        width: atendimentoAberto.value ? '100%' : '0px'
                    }}>
                    <AtendimentoContainer historicoAberto={historicoAberto} atendimentoAberto={atendimentoAberto} />
                </SttBox>
            </SttHidden>

            {
                emChamadaVideo.value &&
                <>
                    <SttHidden xsDown>
                        <SttBox className={classes.videoBox}
                            sx={{
                                width: widthChat
                            }}>
                            {
                                emChamadaVideo.value &&
                                <VideoScreenBBB atendimentoAberto={atendimentoAberto} />
                            }
                        </SttBox>
                        <SttBox sx={{
                            border: ".05px solid #e9e9e9",
                        }} />
                    </SttHidden>

                    <SttHidden smUp>
                        <SttBox className={classes.videoBox}
                            sx={{
                                width: atendimentoAberto.value ? '0px' : '100%'
                            }}>
                            {
                                emChamadaVideo.value &&
                                <VideoScreenBBB atendimentoAberto={atendimentoAberto} />
                            }
                        </SttBox>
                    </SttHidden>
                </>
            }
            {
                historicoAberto.value &&
                <>
                    <SttHidden xsDown>
                        <SttBox className={classes.historicoBox}>
                            <HistoricoContainer historicoAberto={historicoAberto} />
                        </SttBox>
                    </SttHidden>
                    <SttHidden smUp>
                        <Drawer
                            className={classes.drawer}
                            variant="persistent"
                            anchor="right"
                            open={true}
                            classes={{
                                paper: classes.drawerPaper,
                            }}>
                            <HistoricoContainer historicoAberto={historicoAberto} />
                        </Drawer>
                    </SttHidden>
                </>
            }
        </SttBox>
    );
}

export default PainelDireita;