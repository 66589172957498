import React, { useContext } from "react";
import {
    SttMensagem,
    SttMensagemAnexo,
    SttMensagemAviso,
    SttTranslateHook
} from '@stt-componentes/core';
import usuario from "../../signals/usuario";
import { useSignal } from "@preact/signals-react";

const ExibicaoMensagemContainer = ({ mensagens = [] }) => {
    const { strings } = useContext(SttTranslateHook.I18nContext);
    useSignal();

    return (
        mensagens.map((msg, index) => {
            if (msg.notificacao) {
                return <SttMensagemAviso
                    {...msg}
                    minWidth='10%'
                    mensagem={msg.mensagem}
                    key={index}
                    atendimento={msg.atendimento}
                />
            }

            let esconderNomeEnviador = false;
            let proprioUsuarioEnvio = false;
            const msgAnterior = mensagens[index - 1];

            let idEnviador;
            if (usuario.value.idPaciente) {
                idEnviador = msg.responsavel?.id || msg.responsavel?.idPaciente;
                proprioUsuarioEnvio = parseInt(usuario.value.idPaciente) === parseInt(idEnviador);
            } else {
                idEnviador = msg.responsavel?.id || msg.responsavel?.idfuncionario;
                proprioUsuarioEnvio = parseInt(usuario.value.idFuncionario) === parseInt(idEnviador);
            }

            if (msgAnterior?.responsavel && msg?.responsavel) {
                const idEnviadorAnterior = msgAnterior.responsavel.id || msgAnterior.responsavel.idFuncionario || msgAnterior.responsavel.idPaciente;
                esconderNomeEnviador = parseInt(idEnviadorAnterior) === parseInt(idEnviador);
            }

            if (msg.timestamp && !(msg.timestamp instanceof Date)) {
                msg.dataHora = new Date(msg.timestamp)
            }
            if (!(msg.dataHora instanceof Date)) {
                msg.dataHora = new Date(msg.dataHora)
            }

            if (msg.anexo) {
                return <SttMensagemAnexo
                    key={index}
                    nome={msg.anexo.nome}
                    url={msg.anexo.url}
                    imagem={msg.anexo.imagem}
                    proprioUsuarioEnvio={proprioUsuarioEnvio}
                    esconderNomeEnviador={esconderNomeEnviador}
                    usuario={{ nome: msg.responsavel.nome }}
                    strings={strings}
                />
            }

            return <SttMensagem
                minWidth='10%'
                key={index}
                mensagem={msg.mensagem}
                proprioUsuarioEnvio={proprioUsuarioEnvio}
                esconderNomeEnviador={esconderNomeEnviador}
                timestamp={msg.dataHora.getTime()}
                usuario={{ nome: msg.responsavel.nome }}
            />
        })
    );
};

export default ExibicaoMensagemContainer;